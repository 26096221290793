.mainContainer {
    padding: 1rem;
}

.title {
    margin-bottom: 1rem;
}

.dividerh {
    width: 1px;
    /* Width of 1 pixel */
    background-color: #d1d5db;
    /* Adjust to the specific gray color */
    margin-left: 2.5rem;
    /* Left margin of 40px */
    margin-right: 0.5rem;
    /* Horizontal margin of 8px on both sides */
    margin-left: 40px;
    /* Specifically for left margin */
    margin-right: 8px;
    /* Specifically for right margin */
}

.dividerv {
    border-bottom: 1px solid #d1d5db;
    /* Adjust to the specific gray color */
    height: 1px;
    /* Height of 1 pixel */
}

.doc {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* 1 column by default */
    gap: 1rem;
    /* 16px gap */
    margin-bottom: 1rem;
    /* 16px bottom margin */
}

@media (min-width: 768px) {
    .doc {
        grid-template-columns: repeat(3, 1fr);
        /* 3 columns on medium screens */
    }
}

.radiodisplay {
    display: flex;
    /* Use flexbox layout */
    flex-wrap: wrap;
    /* Allow items to wrap onto the next line */
    margin-bottom: 1rem;
    /* 16px bottom margin */
}

/* Set a basis for each item to ensure they fit well */
.radiodisplay>div {
    flex: 1 1 calc(20% - 25px);
    /* Each item takes 20% of the width minus gap */
    min-width: 150px;
    /* Set a minimum width to ensure items don't get too small */
}

.research {
    display: grid;
    /* Use grid layout */
    grid-template-columns: repeat(1, 1fr);
    /* One column by default */
    gap: 1rem;
    /* 16px gap between items */
    margin-bottom: 1rem;
    /* 16px bottom margin */
}

/* Media query for medium screens and larger */
@media (min-width: 768px) {
    .research {
        grid-template-columns: repeat(3, 1fr);
        /* Three columns on medium screens */
    }
}

.titletext {
    font-weight: 300;
    /* Light font weight */
    color: #6b7280;
    /* Adjust to the specific gray color (gray-500) */
    font-size: 0.875rem;
    /* Small font size (14px) */
    margin-bottom: 0.5rem;
    /* 8px bottom margin */
}

.buttondisplay {
    display: flex;
    /* Use flexbox layout */
    justify-content: flex-end;
    /* Align items to the end */
    gap: 0.5rem;
    /* 8px gap between items */
}


.headingBtns {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;

}

.active {
    background: transparent !important;
    color: #37C577 !important;
    border-bottom: 0 !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;

}

.active span {
    padding: 0 !important;

}

input[type="date"] {
    -webkit-appearance: none;

}

.selectBox {
    width: 100%;
    padding: 10px;
    height: 48px;
    line-height: 40px;
}

.hr {
    display: inline-block;
    height: 1px;
    background: #DBDBDB;
    width: 100%;
    margin: 24px 0;
}

.radioBtnsFields {
    display: flex;
}

.radioBtnsGrp {
    display: flex !important;
    flex-direction: row !important;
}

.multiSelect {
    height: 48px;
}


.proceedModal {
    border-radius: 20px !important;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    text-align: center;
    padding: 0 0 20px 0;
}

.proceedModal .successImg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.proceedModal h2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: #232323;


}

.proceedModal p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #232323;
}

.proceedModal p span {
    color: #402295;
    font-weight: 600;
}
 