.main {
    width: 100%;
}

.logo_container img {
    width: 8.375rem;
    height: 2.875rem;
    flex-shrink: 0;
}

.logo_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.main > * {
    box-shadow: 0;
}