.sidebarContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebarItemButton {
  background-color: transparent;
  border: 2px solid #fff !important;
  margin: 0 16px;
}

.sidebarItemButton:hover,
.sidebarItemButton.active {
  background-color: transparent;
  border: 2px solid #cebcff !important;
  border-radius: 0 20px 20px 20px;
  /* box-shadow: 3px 3px 10px 0px #0000001C; */
  white-space: nowrap;

}

 
.menuIcons {
  min-width: 40px !important;
}

.sidebarIcon {
  width: 24px;
  height: 24px;
}

.sidebarLabel {
  white-space: nowrap;
}