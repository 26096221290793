.container {
    padding: 20px 0 0;
    margin: 40px 40px 0;
}


.profileTop {
    display: flex;
    flex-direction: column;
    padding: 40px 30px 25px;
    justify-content: space-between;
    /* background: url("../../../assets/images/profile-bg.svg") no-repeat left top; */
    background-size: 100%;
    min-height: 125px;
    border: 1px solid #E2E3E5;
    background: #fafafa;
    border-radius: 30px;
    margin-bottom: 20px;
}

.imageContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin: -85px 0 0 0;
    gap: 45px
}

.imageItem {
    position: relative;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border: 3px solid #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.donor,
.female,
.male {
    position: relative;
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 700;
    display: flex;
    text-align: left;
    color: #7A5A5A;

}

.imageContainer .female {
    color: #e6118d;
  }
  
  .imageContainer .male {
    color: #0e5fac;
  }
.genderIcon {
    width: 26px;
    height: 26px;
    background: #F6C7EC;
    border-radius: 100%;
    text-align: center;
    color: #fff;
    font-size: 30px;
    line-height: 29px;
    rotate: 45deg;
    position: absolute;
    margin: 3px 0 0 50px;
    z-index: 9;
}

.imageItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imageItem.initial {
    background-color: #f0c0c0;
    /* Light color for background */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: bold;
    color: #333;
}

.imageItem.initial .gender-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #f5b1d1;
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    font-size: 18px;
}

.image-container::before,
.image-container::after {
    content: '';
    position: absolute;
    width: 40%;
    height: 3px;
    background-color: #e0e0e0;
    top: 50%;
    left: 15%;
    z-index: -1;
}

.image-container::after {
    left: 55%;
}

.imageItem:not(:first-child)::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    top: 50%;
    left: -70px;
    transform: translateY(-50%);
    z-index: -1;
    border: 3px solid #e0e0e0;
}


.profileBasic label {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #5a5a5a;

}

.profileBasic p {
    padding: 0;
    margin: 6px 0 0 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #232323;
    text-transform: capitalize;
}

.patientInfoContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

@media (max-width: 950px) {
    .patientInfoContainer{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        flex-direction: column;
    }
}


