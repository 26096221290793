@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.main {
  position: fixed;
  background: #F9F7FF;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
}

.main_page {
  flex-grow: 1;
  margin: 0 1.5rem;
  margin-right: '0px'
}

.page {
  flex-grow: 1;
  margin: 0px 1.5rem;
  overflow-y: scroll;
  height: calc(100vh - 104px);
  background-color: white;
  border-radius: 20px;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

.sidebar { 
  border-radius: 25px;
  background: #fff url(../assets/images/watermarkVPlus.svg)no-repeat -70px bottom;
  background-size: 150%;
}

.page::-webkit-scrollbar {
  display: none;
  /* WebKit-based browsers */
}

.outletContent {
  border-radius: 20px;
  height: calc(100vh - 87px);
  overflow: auto; 
  background-color: #FFFFFF;
}

::-webkit-scrollbar {
  width: 6px;
  height: 10px;

}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #c2c9d2;
}

.content {
  display: flex;
  gap: 1rem;
  margin: 0 1.2rem;
  margin-right: 0.4rem;
  padding-top: 0.7rem;
}

@media screen and (max-width: 950px) {
  .sidebar {
    display: none;
  }

  .main {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .main_page {
    margin: 0;
    padding: 0;
  }

  .content {
    flex-direction: column;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    padding-top: 1rem;
  }

  .outletContent {
    border-radius: 20px;
    background-color: #FFFFFF;
    margin: 0;
    padding: 0;
  }

}