.mainContainer {
  padding: 1rem;
}

.title {
  margin-bottom: 1rem;
}

.dividerh {
  width: 1px; 
  background-color: #d1d5db; 
  margin-left: 2.5rem; 
  margin-right: 0.5rem; 
  margin-left: 40px; 
  margin-right: 8px; 
}

.dividerv {
  border-bottom: 1px solid #d1d5db; 
  height: 1px; 
}

.doc {
  display: grid;
  grid-template-columns: repeat(1, 1fr); 
  gap: 1rem; 
  margin-bottom: 1rem; 
}

@media (min-width: 768px) {
  .doc {
    grid-template-columns: repeat(3, 1fr); 
  }
}

.radiodisplay {
  display: flex; 
  flex-wrap: wrap; 
  margin-bottom: 1rem; 
}
 
.radiodisplay>div {
  flex: 1 1 calc(20% - 25px); 
  min-width: 150px; 
}

.research {
  display: grid; 
  grid-template-columns: repeat(1, 1fr); 
  gap: 1rem; 
  margin-bottom: 1rem; 
}
 
@media (min-width: 768px) {
  .research {
    grid-template-columns: repeat(3, 1fr); 
  }
}

.titletext {
  font-weight: 300; 
  color: #6b7280; 
  font-size: 0.875rem; 
  margin-bottom: 0.5rem; 
}

.buttondisplay {
  display: flex; 
  justify-content: flex-end; 
  gap: 0.5rem; 
}

.checkboxGroup {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.checkboxGroup label {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-size: 0; 
}

.checkboxGroup input[type="checkbox"] {
  appearance: none; 
  width: 20px;
  height: 20px;
  border-radius: 6px; 
  border: 2px solid #BBBBBB; 
  background-color: #FFFFFF; 
  position: relative; 
  z-index: 1; 
}

.checkboxGroup input[type="checkbox"]:checked {
  background-color: #37C577; 
  border: 1px solid #37C577; 
}
 
.checkboxGroup input[type="checkbox"]:checked::before {
  content: ''; 
  position: absolute;
  top: 1px; 
  left: 6px; 
  width: 6px; 
  height: 12px; 
  border: solid white; 
  border-width: 0 2px 2px 0; 
  transform: rotate(45deg); 
  z-index: 2; 
}

.customCheckbox {
  display: flex;
  align-items: center;
  gap: 10px; 
  padding: 0;
  border: 2px solid transparent; 
  border-radius: 4px; 
  transition: border-color 0.3s ease;
}

.checkboxGroup input[type="checkbox"]:checked+.customCheckbox {
  border-color: var(--secondary-color); 
}

.checkboxLabel {
  font-family: 'Montserrat'; 
  font-size: 0.875rem; 
  font-weight: 600; 
  line-height: 1.07rem; 
  color: rgba(35, 35, 35, 1); 
  opacity: 0.3; 
  transition: opacity 0.3s ease; 
  font-size: 0;
}

.checkboxGroup input[type="checkbox"]:checked+.customCheckbox .checkboxLabel {
  opacity: 1; 
}

.checkboxItem input[type="checkbox"]:disabled + .checkboxIndicator {
  opacity: 0.3;  
}


.logo {
  width: auto;
  height: 30px;
  /* Logo height */
}



.checkboxGroup img {
  margin: 10px 0 0 0;
}
 
.agree {
  align-items: flex-start;
  margin: 0 10px;
  text-align: justify;


}

label.agreeCheckbox {
  margin-right: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 15px;

}

.agreeLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #4A5157;
}

.sectionContainer {
  display: flex;
  gap: 24px;
}

.col1 {
  flex-basis: 50%;
  padding: 24px 24px 24px 0;
  border-right: 1px solid #DBDBDB;
}

.hr {
  display: inline-block;
  height: 1px;
  background: #DBDBDB;
  width: 100%;
  margin: 24px 0;
}

.no-border {
  border: none;
}

.MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.imageContainer {
  display: flex;
  font-size: 20px;
}

.donorImg,
.female,
.male, 
.neutral{
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EBEBEB;
  border: 3px solid #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 700;
  line-height: 43.5px;
  text-align: left;
  color: #7A5A5A;
}

.imageContainer img {
  position: relative;
  width: 45px;
  height: auto; 
}

.imageContainer .female {
  color: #e6118d;
}

.imageContainer .male {
  color: #0e5fac;
}

.imageContainer .neutral {
  color: #0e5fac; 
}.imageContainer .neutral img{
  width: 35px; 
}
.proceedModal {
  border-radius: 20px !important;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  text-align: center;
  padding:0 20px 20px;
}

.proceedModal .successImg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.proceedModal h2 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: #232323;


}

.proceedModal p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #232323;
}

.proceedModal p span {
  color: #402295;
  font-weight: 600;
} 
.readOnlyField {
  cursor: not-allowed; 
}

.readOnlyField:hover {
  background-color: rgba(255, 0, 0, 0.1);  
  border-color: red; 
}
 

@media (max-width: 950px) {
  .sectionContainer {
    flex-direction: column !important;
  }

  .col1 {
    flex-basis: 100% !important;
    padding: 24px 24px 24px 0 !important;
    border-right: none !important;
  }
}