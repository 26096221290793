.tabGroup {
    display: flex;
    justify-content: space-between;
    gap: 0;
}

.tabGroup button {
    color: #000; 
    border-radius: 0;
    margin: 10px;
    max-width: 31%;
    min-width: 31%;
    cursor: pointer;
    border-radius: 50px;
}  