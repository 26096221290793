html,
body {
  overflow-x: hidden;
}

:root {
  --primary-color: #DFD6F5;
  --secondary-color: #7D54F3;
  --text-color: #2A2A2A;
  --button-bg-color: var(--primary-color);
  --button-text-color: #2A2A2A;
  --button-hover-bg-color: darken(var(--primary-color), 10%);
  --heading-color: #666666;
  --color-black: #000000;
  --color-white: #FFFFFF;
  --color-grey: #4A5157;
  --color-grey-light: #D7D7D7;
  --color-error: #E70000;
}

button.button,
button.reset {
  background-color: var(--button-bg-color) !important;
  color: var(--button-text-color) !important;
  padding: 10px 20px !important;
  border: none !important;
  border-radius: 12px !important;
  cursor: pointer !important;
  transition: background-color 0.3s !important;
  text-transform: uppercase !important;
  height: 48px !important;
  font-family: 'Montserrat' !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  line-height: 1.375rem;
  width: 200px !important;
  transition: all 0.56s ease-in !important;
}

button.reset {
  color: var(--text-color) !important;
  background: var(--color-white) !important;
  border: 1px solid var(--color-grey) !important;
}

button.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
  min-width: auto;
  background: var(--color-grey-light);
  border-radius: 50%;
  width: 26px;
  height: 26px;
  color: var(--color-white);
}

button:hover {
  opacity: 0.7 !important;
}

.button:hover {
  background-color: var(--button-hover-bg-color);
}

.input .MuiInputBase-input {
  cursor: pointer;
}

input[type=radio] {
  cursor: pointer;
}

.link {
  color: var(--secondary-color) !important;
  text-transform: capitalize !important;
  text-decoration: underline !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.labalButton {
  font-family: 'Montserrat' !important;
  background-color: var(--primary-color) !important;
  color: var(--button-text-color) !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}

.btn {
  height: 48px;
  font-family: 'Montserrat';
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  color: var(--button-text-color);
  background: #DFD6F5 !important;
  border: transparent;
  padding: 10px;
  border-radius: 5px;
  text-transform: uppercase;
  width: 200px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

h2 {
  font-family: 'Montserrat' !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 21.94px !important;
  color: var(--heading-color) !important;
  margin: 0 !important;
  margin-block-end: 10px !important;

}

h3 {
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: 700;
  line-height: 21.94px;
  text-align: left;
  color: var(--heading-color);
}

textarea {
  font: inherit;
  letter-spacing: inherit;
  margin-top: 12px !important;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0 0 20px;
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 12px;
  padding: 12px;
}

.error {
  color: var(--color-error)!important;
}
.NA{
  color: var(--color-error)!important;
  font-weight: 700 !important;
}