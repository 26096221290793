.name {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: var(--Text-Primary, #232323);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Nunito;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem; /* 142.857% */
}

.name img {
    height: 1rem;
    width: 1rem;
}