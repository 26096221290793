.container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.maintitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #272d36;
}

.divider {
  height: 1px;
  background-color: #DBDBDB;
  margin: 32px 0;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  /* Adjust the gap between items as needed */
  /* margin-left: 20px; */
}

.detail {
  display: flex;
  flex-direction: column;
  background: #FBFBFB;
  border-radius: 12px;
  padding: 12px;
}

.details {
  margin-top: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  color: #272d36;
}

.detailstitle {
  color: #5a5a5a;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}


.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}




.sectionContainer {
  display: flex;
}

.col1 {
  flex-basis: 50%;
  padding: 24px;
  border-right: 1px solid #DBDBDB;
}

.testLogo{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.logo {
  width: auto;
  height: 34px;
}
@media (max-width: 950px) {
  .sectionContainer {
    flex-direction: column !important;
  }

  .col1 {
    flex-basis: 100% !important;
    padding: 24px 24px 24px 0 !important;
    border-right: none !important;
  }
}