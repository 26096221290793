.custom_table .custom_table_head {
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.3px;
    text-align: left;
    color: #4A5157;
    background-color: rgba(250, 250, 250, 1);
}

.custom_table .custom_table_body {
    padding: 0px;
    cursor: pointer;
    color: #272D36;
    background-color: rgba(250, 250, 250, 1);
    transition: background-color 0.3s ease;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.custom_table .custom_table_body td {
    color: #272D36;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    color: #272D36;
}

.custom_table .custom_table_body:hover {
    background-color: #ebeaf1;
}

.custom_table {
    border-collapse: separate;
    border-spacing: 0 5px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;

}

.styles.custom_table_cell {
    padding: 0px !important;
}

.tb_main_text {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.2px;
    text-align: left;
}

button.viewBtn {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #37C577;
    text-transform: capitalize;
    text-decoration: underline;
}

button.viewBtn:hover {
    background: transparent;
}

span.aiGradeBG {
    background: #AC8FFF;
    height: 26px;
    padding: 4px 12px;
    gap: 6px;
    border-radius: 30px;
    width: 54px;
    display: flex;
    justify-content: space-between;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #fff;

}

span.aiGradeBG:empty {
    display: none;
}

.aiScoreBG {
    background: linear-gradient(90deg, #E9EAFF 0%, rgba(243, 244, 255, 0) 100%),
        linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    padding: 5px 6px;
    border-radius: 4px;
}

.aiScoreText {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    background: linear-gradient(90deg, #4066EF 0%, #303263 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}