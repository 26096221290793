 .mainContainer {
     padding: 1rem;
 }

 .title {
     margin-bottom: 1rem;
 }

 .dividerh {
     width: 1px;
     background-color: #d1d5db;
     margin-left: 2.5rem;
     margin-right: 0.5rem;
     margin-left: 40px;
     margin-right: 8px;
 }

 .dividerv {
     border-bottom: 1px solid #d1d5db;
     height: 1px;
 }

 .doc {
     display: grid;
     grid-template-columns: repeat(1, 1fr);
     gap: 1rem;
     margin-bottom: 1rem;
 }

 .smallText {
     font-size: 12px;
     margin: 10px 0;
     display: block;
     color: #6a6a6a;
 }

 .smallText span {
     font-weight: 500;
 }

 .noCycleHead {
     text-align: center;
     font-size: 30px;
     color: var(--color-error) !important;
 }

 .btnsContainer {
     display: flex !important;
     justify-content: center !important;
     align-items: center !important;

 }

 .btnsContainer button.proceedBtn {
     width: auto !important;
     margin-bottom: 10px;
 }

 /* Container styling */
 .watermarkContainer {
     position: relative;
     padding: 20px;
     overflow: hidden;min-height: 80px;
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
 }

 .watermarkContainer::before {
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-image: url('../../assets/images/watermark.svg'); 
     background-size:contain;
     background-position: center;
     background-repeat: no-repeat;
     opacity: 0.1; 
 }

 @media (min-width: 768px) {
     .doc {
         grid-template-columns: repeat(3, 1fr);
         /* 3 columns on medium screens */
     }
 }

 .radiodisplay {
     display: flex;
     /* Use flexbox layout */
     flex-wrap: wrap;
     /* Allow items to wrap onto the next line */
     margin-bottom: 1rem;
     /* 16px bottom margin */
 }

 /* Set a basis for each item to ensure they fit well */
 .radiodisplay>div {
     flex: 1 1 calc(20% - 25px);
     /* Each item takes 20% of the width minus gap */
     min-width: 150px;
     /* Set a minimum width to ensure items don't get too small */
 }

 .research {
     display: grid;
     /* Use grid layout */
     grid-template-columns: repeat(1, 1fr);
     /* One column by default */
     gap: 1rem;
     /* 16px gap between items */
     margin-bottom: 1rem;
     /* 16px bottom margin */
 }

 /* Media query for medium screens and larger */
 @media (min-width: 768px) {
     .research {
         grid-template-columns: repeat(3, 1fr);
         /* Three columns on medium screens */
     }
 }

 .titletext {
     font-weight: 300;
     /* Light font weight */
     color: #6b7280;
     /* Adjust to the specific gray color (gray-500) */
     font-size: 0.875rem;
     /* Small font size (14px) */
     margin-bottom: 0.5rem;
     /* 8px bottom margin */
 }

 h6 {
     margin-top: 15px !important
 }

 h2.title {
     color: #402295;
     font-size: 20px;
     margin: 0 !important;
 }

 .buttondisplay {
     display: flex;
     /* Use flexbox layout */
     justify-content: flex-end;
     /* Align items to the end */
     gap: 0.5rem;
     /* 8px gap between items */
 }

 button.addRow {
     background-color: transparent;
     box-shadow: none;
     color: var(--secondary-color);
     font-size: 14px;
     font-weight: 600;
 }

 .testBtns {
     padding: 10px 0 30px !important;

 }

 .buttonsContainer {
     display: flex;
     gap: 15px;
 }

 .textField {
     width: 100%;
 }

 .selectField {
     padding: 0;
 }

 .selectField.MuiSelect-select {
     padding: 0;
 }

 .active {
     background: transparent !important;
     color: #37C577 !important;
     border-bottom: 0 !important;
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;

 }

 .active span {
     padding: 0 !important;

 }

 .selectBox {
     width: 100%;
     padding: 10px;
     height: 48px;
     line-height: 40px;
 }